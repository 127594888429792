let translationForFileType = {
    OpFmVergabeBMPlanungsleistung: {
        'Sonstige_Dokumente': 'Sonstige Unterlagen',
        'Erklaerungen_des_ausgewaehlten_TKU': 'Erklärungen des ausgewählten TKU',
        'Dokumentation_des_Auswahlverfahrens': 'Dokumentation des Auswahlverfahrens',
        'Versicherung_zum_Vertragsinhalt': 'Versicherung zum Vertragsinhalt',
        'Versicherung_zum_Betreiberangebot_und_Durchfuehrung_des_der_Auswahlverfah-ren_s': 'Versicherung zum Betreiberangebot und Durchführung des/der Auswahlverfah-ren(s)',
    },
    OpFmVergabeBMBauleistung: {
        'Sonstige_Dokumente': 'Sonstige Unterlagen',
        'Erklaerungen_des_ausgewaehlten_TKU': 'Erklärungen des ausgewählten TKU',
        'Dokumentation_des_Auswahlverfahrens': 'Dokumentation des Auswahlverfahrens',
        'Versicherung_zum_Vertragsinhalt': 'Versicherung zum Vertragsinhalt',
        'Versicherung_zum_Betreiberangebot_und_Durchfuehrung_des_der_Auswahlverfah-ren_s': 'Versicherung zum Betreiberangebot und Durchführung des/der Auswahlverfah-ren(s)',
    },
    OpFmVergabeBMWeitere: {
        'Sonstige_Dokumente': 'Sonstige Unterlagen',
        'Erklaerungen_des_ausgewaehlten_TKU': 'Erklärungen des ausgewählten TKU',
        'Dokumentation_des_Auswahlverfahrens': 'Dokumentation des Auswahlverfahrens',
        'Versicherung_zum_Vertragsinhalt': 'Versicherung zum Vertragsinhalt',
        'Versicherung_zum_Betreiberangebot_und_Durchfuehrung_des_der_Auswahlverfah-ren_s': 'Versicherung zum Betreiberangebot und Durchführung des/der Auswahlverfah-ren(s)',
    },
    OpFmVergabe: {
        'Sonstige_Dokumente': 'Sonstige Unterlagen',
        'Erklaerungen_des_ausgewaehlten_TKU': 'Erklärungen des ausgewählten TKU',
        'Dokumentation_des_Auswahlverfahrens': 'Dokumentation des Auswahlverfahrens',
        'Versicherung_zum_Vertragsinhalt': 'Versicherung zum Vertragsinhalt',
        'Versicherung_zum_Betreiberangebot_und_Durchfuehrung_des_der_Auswahlverfah-ren_s': 'Versicherung zum Betreiberangebot und Durchführung des/der Auswahlverfah-ren(s)',
    },
};

export {translationForFileType};
