import {translationForFileType} from "../../../../../OP/map/translations/ajaxfileuploader";

/* global global,jQuery,EXIF
 * jslint browser:true
 */

/**
 * Wenn du ein Feature nicht benutzen willst, so übergebe einfach nicht den API-Punkt.
 * Wenn du zB nicht löschen erlauben willst dann setze als `data-api_delete` maximal einen Leerstring.
 * Welche APIpunkte es gibt findest du unter `var api = `
 *
 * @author pbierans, 2019-11-08, 2020-02-04, 2020-10-22, 2020-03-31, 2023-02-08
 *
 * Hier die Ereigniskette:
 *
 * on afu-get-uploaded-files
 *     debounce und delay
 *     trigger afu-get-uploaded-files-do
 *
 * on afu-get-uploaded-files-do
 *     if api.get läd die Dateiliste vom Server
 *     trigger afu-update-uploaded-filelist or
 *     trigger globally: afu-get-uploaded-files
 *
 * on afu-update-uploaded-filelist
 *     rendert Dateiliste von api.get
 *     trigger afu-update-progress-filelist-counter
 *
 * on afu-update-progress-filelist
 *     rendert Warteliste aus Queue
 *     trigger afu-update-progress-filelist-counter
 *
 * on afu-update-progress-filelist-counter
 *     aktualisiert die Counter für Dateiliste und Warteliste
 *
 * on afu-cancel-file
 *     entfernt Datei aus Queue
 *     trigger afu-update-progress-filelist
 *
 * on afu-cancel-all
 *     entfernt alle Dateien aus Queue
 *     trigger afu-update-progress-filelist
 *
 * on afu-delete-file
 *     löscht Datei vom Server
 *     trigger afu-get-uploaded-files
 *
 * on afu-delete-all
 *     löscht alle Dateien (bis zu maxDelete) vom Server
 *     trigger afu-get-uploaded-files
 *
 * on afu-upload
 *     arbeitet die Warteliste ab. async.
 *     trigger afu-update-progress-filelist
 *     trigger afu-update-progress-filelist-counter
 *     on uploadFile.done
 *         trigger afu-update-progress-filelist
 *         trigger afu-update-uploaded-filelist
 *         trigger afu-upload
 *         trigger afu-get-uploaded-files
 *
 * $root.on("drop")
 *     trigger afu-update-progress-filelist
 *     trigger afu-upload
 *
 * @var {window} global
 */
jQuery(function () {

    var addflash = global.addflash;
    var debounce = global.debounce;

    var $body = jQuery("body");
    var $document = jQuery(document);

// <editor-fold desc="helper-functions">
    var escapeHtml = function (text) {
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };

    var generateRandomId = function () {
        return Math.random().toString(36).substring(2, 15);
    };

    /**
     * @param {File} file
     * @param {?string} file.mime
     * @returns {string}
     */
    var getFileExtension = function (file) {
        var filename = file.name;
        var dot = filename.lastIndexOf('.') + 1;
        var ext = "";
        if (dot > 0) {
            ext = filename.substring(dot);
        }
        if (ext === "") {
            if (file.mime !== undefined) {
                ext = file.mime.split('/')[1];
            } else {
                return '';
            }
        }
        if (ext !== undefined && ext !== '') {
            ext = ext.toLowerCase()
        }
        return ext;
    };

    var postRequest = function (url, data) {
        var fD = new FormData();
        if (data) {
            for (var i in data) {
                if (data.hasOwnProperty(i)) {
                    fD.append(i, data[i]);
                }
            }
        }

        return jQuery.ajax({
            url: url,
            type: "POST",
            data: fD,
            processData: false,
            contentType: false
        });
    };

    var filesize4humans = function (bytes) {
        var nf = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2});
        return nf.format(bytes / 1024) + " kB";
    };

    var disableDefaultDragAndDrop = function () {
        $body.not(".no_default_drag_and_drop").addClass("no_default_drag_and_drop")
            .on("dragenter dragover drop", function (event) {
                event.preventDefault();
                event.stopPropagation();
            });
    };
// </editor-fold>

//<editor-fold desc="templates">
    var template_dropzone = jQuery("script#ajaxfileuploader_dropzone").html();
    var template_progress = jQuery("script#ajaxfileuploader_progress").html();
    var template_uploaded = jQuery("script#ajaxfileuploader_uploaded").html();
    var template_uploading_file = jQuery("script#ajaxfileuploader_uploadingfile").html();
    var template_uploaded_file = jQuery("script#ajaxfileuploader_uploadedfile").html();
//</editor-fold>

    $document.on("start load", function () {
        disableDefaultDragAndDrop();
        var $roots = $body.find(".ajaxfileuploader").not(".afu-loaded").addClass("afu-loaded");

        $roots.each(function () {
            var $root = jQuery(this);

//<editor-fold desc="features">
            $root.addClass("edit");
            $root.addClass("download");
//</editor-fold>

//<editor-fold desc="data-attributes">

            var lang = {
                "cancel": $root.data("lang_cancel"),
                "delete": $root.data("lang_delete"),
                "delete_all": $root.data("lang_delete_all"),
                "delete_all_confirm": $root.data("lang_delete_all_confirm"),
                "discard_all": $root.data("lang_discard_all"),
                "dropzone": $root.data("lang_dropzone"),
                "fileextdenied": $root.data("lang_fileextdenied"),
                "select": $root.data("lang_select"),
                "sending": $root.data("lang_sending"),
                "upload": $root.data("lang_upload"),
                "uploaded": $root.data("lang_uploaded"),
                "geodatamissing": $root.data("lang_geodatamissing"),
                "already_in_use": $root.data("lang_already_in_use"),
            };

            var settings = {
                "parent": $root.data("settings_parent"),
                "meta": $root.data("settings_meta"),
                "extwhitelist": $root.data("settings_extwhitelist"),
                "checkgeodatagermany": $root.data("settings_checkgeodatagermany"),
                "storeuploadedfiles": "#" + $root.data("settings_storeuploadedfiles"),
                "full_name": $root.data("settings_full_name"),
                "excludeVerwendungszwecks": $root.data("settings_excludeVerwendungszwecks"),
                "isColumnTypeOfDocumentVisible": $root.data("settings_isColumnTypeOfDocumentVisible"),
            }

            if (settings.extwhitelist === "") {
                settings.extwhitelist = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "zip"];
            } else {
                settings.extwhitelist = settings.extwhitelist.split(",");
            }
//</editor-fold>

//<editor-fold desc="local-variables">
            var maxParallel = 8;
            var maxDelete = 1000;

            var $dropzone = $root;
            var $progress = $root;
            var $uploaded = $root;
            var $uploadedfiles = $root;
            var $progressfiles = $root;
            var $uploadedfilescounter = $root;
            var $progressfilescounter = $root;
            var $inputFile = $root;
            var $inputFileCount = $root;
            var progressfilelist = [];
            var uploadedfilelist = [];
            var parallel = maxParallel; // wieviele uploadslots noch frei sind
            var $storeuploadedfiles = jQuery(settings.storeuploadedfiles);
            var uploadednow = 0;
            var storednow = 0;
//</editor-fold>

//<editor-fold desc="scoped-functions">
            /**
             * @param {File} file
             */
            var removeUploadFile = (file) => {
                freeUploadSlot();
                $root.trigger("afu-update-progress-filelist");
                uploadedfilelist.unshift(file);
                $root.trigger("afu-update-uploaded-filelist");
                if (progressfilelist.length > 0) {
                    $root.trigger("afu-upload");
                } else {
                    $root.trigger("afu-get-uploaded-files");
                    if (parallel === maxParallel) {
                        $root.removeClass("afu-uploading");
                    }
                }
            };

            /**
             * @param {File} file
             * @param {?boolean} file.checked
             * @param {?string} file.md5
             */
            var uploadFile = (file) => {
                if ($root.data("api_put") === "") {
                    return;
                }
                $root.addClass("afu-uploading");

                if ($root.data("api_check") === "") {
                    file.checked = true;
                }
                if (getFileExtension(file) === 'zip') {
                    file.checked = true;
                }
                if (!file.checked) {
                    if (!file.md5) {
                        window.sendMd5ofFileToCallback(
                            file,
                            (md5String) => {
                                file.md5 = md5String;
                                uploadFile(file);
                            }
                        );
                        return;
                    } else {
                        postRequest(
                            $root.data("api_check"),
                            {
                                md5: file.md5,
                                name: file.name,
                                size: file.size,
                            }
                        ).done((result) => {
                            if (result.usages === 0) {
                                file.checked = true;
                                uploadFile(file);
                            } else {
                                removeUploadFile(file);
                                showWarning(file.name + '\n\n' + lang.already_in_use);
                            }
                        });
                    }
                    return;
                }
                if (file.checked) {
                    postRequest(
                        $root.data("api_put"),
                        {
                            parent: $root.data("settings_parent"),
                            meta: JSON.stringify($root.data("settings_meta")),
                            file: file,
                            foerdermittelId: $root.data("api_check").split("/").pop(),
                            extwhitelist: settings.extwhitelist,
                            checkgeodatagermany: settings.checkgeodatagermany,
                        }
                    ).done((result) => {
                        // @ts-ignore
                        $root.trigger('setValueOnFileUploadResponse', [result]);
                        uploadednow++;
                        if (getFileExtension(file) === 'zip') {
                            location.reload();
                            $root.trigger("afu-update-progress-filelist");
                        }
                        removeUploadFile(file);
                        // noinspection JSUnresolvedVariable
                        if (result.duplicateDeleted) {
                            showWarning(file.name + '\n\n' + lang.already_in_use);
                        }
                    });
                }
            };

            /**
             * @param {File} file
             */
            var addFileToProgress = (file) => {
                if (typeof file != "object") return;
                file.tmpid = "tmp_" + generateRandomId();
                uploadednow = 0;
                progressfilelist.push(file);
            };

            /**
             * @param {string} message
             */
            var showWarning = (message) => {
                $body.trigger('addmessage', [
                    'danger',
                    message + ''
                ]);
                return false;
            };

            var geodataNotInGermany = function (geodata) {
                if (geodata === undefined) {
                    return 'Keine Geodaten gefunden.';
                }
                // noinspection JSUnresolvedVariable
                if (geodata.GPSLatitudeRef !== "N" || geodata.GPSLongitudeRef !== "E") {
                    return 'Falsche Hemisphäre.';
                }
                // noinspection JSUnresolvedVariable
                if ((geodata.GPSMapDatum !== undefined) && geodata.GPSMapDatum !== "WGS-84" && geodata.GPSMapDatum !== "WGS84") {
                    return 'Das in dem Bild verwendete Koordinatensystem ist nicht WGS-84.';
                }
                if (isNaN(geodata.latitude) || isNaN(geodata.longitude) || geodata.latitude === 0 || geodata.longitude === 0) {
                    return 'Bild enthält keine gültigen Geokoordinaten.';
                }
                if (!isInDeutschland(geodata.latitude, geodata.longitude)) {
                    return 'Die Geokoordinaten des Bildes liegen nicht in Deutschland.';
                }
                return false;
            };

            var isInDeutschland = function (latitude, longitude) {
                // noinspection RedundantIfStatementJS
                if (longitude < 5 || longitude > 16 || latitude < 47 || latitude > 56) {
                    return false;
                }

                return true;
            };

            var freeUploadSlot = () => {
                parallel++;
                if (parallel >= maxParallel) {
                    $root.removeClass("afu-loading");
                }
                $root.trigger("afu-upload"); // Sollte noch was in der Warteschleife sein, starten wir den Upload-Vorgang erneut
            };

            /**
             * @param {?integer} setstorednow
             */
            var setUploadedFilesTotal = (setstorednow) => {
                if (setstorednow !== undefined) {
                    storednow = setstorednow;
                    $inputFileCount.val(storednow);
                }
                var out = "";
                if (storednow > 0) {
                    out = storednow.toString();
                }
                if (uploadednow > 0 && storednow > 0) {
                    out += " + ";
                }
                if (uploadednow > 0) {
                    out += uploadednow.toString() + " in Bearbeitung";
                }
                if (storednow === -1) {
                    out = "...";
                }
                $uploadedfilescounter.html(out);
                $body.trigger('load');
            };

            /**
             * @param {string} hmtl
             * @param {File} uploadedfilelistitem
             * @param {string} uploadedfilelistitem.id
             * @returns {jQuery}
             */
            var wokeFileRow = function (hmtl, uploadedfilelistitem) {
                var html = template_uploaded_file;
                var fileextension = getFileExtension(uploadedfilelistitem);
                if (fileextension === undefined || fileextension === '') {
                    return jQuery('');
                }
                html = html.replace('__ID__', uploadedfilelistitem.id);
                html = html.replace('__FILENAME__', escapeHtml(uploadedfilelistitem.name));
                if ($root.data('settings_iscolumntypeofdocumentvisible')) {
                    let hochgeladenFuerTyp = uploadedfilelistitem.hochgeladenFuerTyp;
                    if (hochgeladenFuerTyp === undefined) {
                        hochgeladenFuerTyp = $root.data('settings_meta').hochgeladenFuerTyp;
                    }
                    let verwendungszweck = uploadedfilelistitem.verwendungszweck;
                    if (verwendungszweck === undefined) {
                        verwendungszweck = $root.data('settings_meta').verwendungszweck;
                    }
                    html = html.replace('__FILETYPTRANSLATED__', translationForFileType[hochgeladenFuerTyp.trim()][verwendungszweck.trim()]);
                    html = html.replace('__FILENAMECUSTOMCLASS__', 'filenameCustomByDocumentType');
                    html = html.replace('d-none', '');
                }
                html = html.replace('__FILESIZE__', filesize4humans(uploadedfilelistitem.size));
                html = html.replace('__EXT__', fileextension);
                return jQuery(html);
            };
//</editor-fold>

//<editor-fold desc="initializing">
            $root.on("start", () => {
                var $todo = $root.not("afu-loaded");
                if ($todo.length > 0) {
                    $todo.addClass("afu-loaded");
                    $dropzone = jQuery(template_dropzone);
                    $progress = jQuery(template_progress);
                    $uploaded = jQuery(template_uploaded);
                    $inputFile = $dropzone.find("[type=file]");

                    $inputFileCount = $root.find('.ajaxfileuploader-filecount');
                    $uploadedfilescounter = $uploaded.find(".counter");
                    $progressfilescounter = $progress.find(".counter");

                    $progress.find(".action.all.cancel, .action-all-discard").on("click", function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $root.trigger("afu-cancel-all");
                    });

                    var $btnDelAll = $uploaded.find(".action.all.delete, .action-all-delete").on("click", function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $root.trigger("afu-delete-all");
                    });
                    if ($root.data("api_delete_selection") === "") {
                        $btnDelAll.remove();
                    }

                    $root.on("dragenter dragover", (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    });

                    $root.on("drop", (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!event.originalEvent.dataTransfer) return false;

                        var i;
                        if (event.originalEvent.dataTransfer.items) {
                            for (i = 0; i < event.originalEvent.dataTransfer.items.length; i++) {
                                if (event.originalEvent.dataTransfer.items[i].kind === 'file') {
                                    addFileToProgress(event.originalEvent.dataTransfer.items[i].getAsFile());
                                }
                            }
                        } else {
                            for (i = 0; i < event.originalEvent.dataTransfer.files.length; i++) {
                                addFileToProgress(event.originalEvent.dataTransfer.files[i]);
                            }
                        }
                        $root.trigger("afu-update-progress-filelist");
                        $root.trigger("afu-upload");
                    });

                    $progressfiles = $progress.find(".filelist"); // später: foreach progressfilelist with template_uploading_file
                    $uploadedfiles = $uploaded.find(".filelist"); // später: foreach uploadedfilelist with template_uploaded_file

                    if ($root.data("api_put") !== "") {
                        $dropzone.appendTo($root);
                        $progress.appendTo($root);
                    }

                    if ($root.data("api_get") !== "") {
                        $uploaded.appendTo($root);
                    }

                    $dropzone.find(".file").on("click", function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $inputFile.trigger("click");
                    });

                    $inputFile.on("change", function () {
                        var $this = jQuery(this);
                        var tfiles = $this.prop('files');
                        for (var i in tfiles) {
                            if (tfiles.hasOwnProperty(i)) {
                                addFileToProgress($this.prop('files')[i]);
                            }
                        }
                        $this.val("");
                        $root.trigger("afu-update-progress-filelist");
                        $root.trigger("afu-upload");
                    });

                    $root.trigger("afu-get-uploaded-files");
                }
            });
//</editor-fold>

//<editor-fold desc="logic">
            var callback_guf = () => {
                $root.trigger("afu-get-uploaded-files-do");
            };
            $root.on("afu-get-uploaded-files", debounce(callback_guf, 1000, false, generateRandomId()));
            $root.on("afu-get-uploaded-files-do", () => {
                    if ($root.data('api_get') !== "") {
                        $root.addClass("afu-loading");
                        setUploadedFilesTotal(-1);
                        postRequest(
                            $root.data('api_get'),
                            {
                                parent: $root.data('settings_parent'),
                                meta: JSON.stringify($root.data('settings_meta')),
                            }
                        ).done(function (data) {
                                $root.removeClass("afu-loading");
                                uploadednow = 0;
                                if (data !== null) {
                                    if (data.response)
                                        uploadedfilelist = data.response; // ADB, DMS
                                    if (data.results)
                                        uploadedfilelist = data.results;// PZS
                                    if (data.results === undefined && data.response === undefined && data.length > 0)
                                        uploadedfilelist = data; // lokale Dateien

                                    if (uploadedfilelist === undefined) {
                                        uploadedfilelist = []; // bei server error
                                    }

                                    // noinspection JSUnresolvedVariable
                                    if (data.pagination !== undefined && data.pagination.totalItems !== undefined) {
                                        // noinspection JSUnresolvedVariable
                                        if (data.pagination.hasMoreItems !== undefined && data.pagination.hasMoreItems === 0) {
                                            // es gibt einen Cachefehler in PZS, sodass `totalItems` erst 15 Sekunden
                                            // verzögert berechnet wird; Das verstecken wir hier mit folgender Zeile:
                                            data.pagination.totalItems = data.pagination.count;
                                        }
                                        var ti = data.pagination.totalItems;
                                        setUploadedFilesTotal(ti);
                                        $storeuploadedfiles.val(ti);
                                    }
                                }

                                $root.trigger("afu-update-uploaded-filelist");
                            }
                        )
                        ;
                    } else {
                        $root.removeClass("afu-loading");
                        $body.trigger("afu-get-uploaded-files", jQuery.extend($root.data("settings_meta"), {"alfrescosearch": true}));
                    }
                }
            ).on('setValueOnFileUploadResponse', function (event, result) {
                if (result.id && result.verwendungszweck &&
                    result.verwendungszweckByEntity && result.verwendungszweck === result.verwendungszweckByEntity) {
                    $root.parent().parent().parent().find('#' + result.verwendungszweckByEntity + '_name').val(result.id);
                }
            });

            $root.on("afu-update-uploaded-filelist", () => {
                $uploadedfiles.html('');

                var deleteClick = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $this = jQuery(this);
                    var $file = $this.closest(".file");
                    $this.trigger("afu-delete-file", [$file.data("id")]);
                    $file.remove();
                };

                var downloadClick = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if ($root.data("api_download") === "") {
                        return;
                    }
                    var $this = jQuery(this);
                    var url = $root.data("api_download") + "?parent=" + $root.data("settings_parent") + "&id=" + $this.closest(".file").data("id"); // + "&meta=" + meta;
                    window.open(url, 'Download');
                };

                for (var i in uploadedfilelist) {
                    if (!uploadedfilelist.hasOwnProperty(i)) {
                        continue;
                    }
                    var $html = wokeFileRow(template_uploaded_file, uploadedfilelist[i]);
                    var $btnDelete = $html.find(".delete").on("click", deleteClick);
                    if ($root.data("api_delete") === "") {
                        $btnDelete.remove();
                    }

                    if ($root.data("api_download") === "") {
                        $html.find(".filename").removeClass('filename'); // kein hover Effekt mehr
                    } else {
                        $html.find(".filename").on("click", downloadClick);
                    }
                    if ($root.data('settings_excludeverwendungszwecks') !== undefined && uploadedfilelist[i].verwendungszweck === $root.data('settings_excludeverwendungszwecks')) {
                        continue;
                    }
                    $uploadedfiles.append($html);
                }
                $root.toggleClass('is-loaded', uploadedfilelist.length > 0);

                if (storednow === -1) { // für APIs ohne pagination.totalItems
                    setUploadedFilesTotal(uploadedfilelist.length);
                }
                $root.trigger("afu-update-progress-filelist-counter");
            });

            $root.on("afu-update-progress-filelist", () => {
                $progressfiles.html('');
                var cancelClick = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $this = jQuery(this);
                    $this.trigger("afu-cancel-file", [$this.closest(".file").data("id")]);
                };
                for (var i in progressfilelist) {
                    if (!progressfilelist.hasOwnProperty(i)) {
                        continue;
                    }
                    var $html = wokeFileRow(template_uploading_file, progressfilelist[i]);
                    $html.find(".fa-ban").on("click", cancelClick);
                    $progressfiles.append($html);
                }
                $root.trigger("afu-update-progress-filelist-counter");
            });

            $root.on("afu-update-progress-filelist-counter", () => {
                var running = maxParallel - parallel;
                var waiting = progressfilelist.length;
                var out = "";
                if (running > 0) {
                    out += running.toString();
                }
                if (running > 0 && waiting > 0) {
                    out += " + ";
                }
                if (waiting > 0) {
                    out += waiting.toString() + " wartend";
                }
                $progressfilescounter.html(out);
                setUploadedFilesTotal();
            });

            $root.on("afu-cancel-file", (event, id) => {
                delete progressfilelist[id];
                $root.trigger("afu-update-progress-filelist");
            });

            $root.on("afu-cancel-all", () => {
                progressfilelist = [];
                $root.trigger("afu-update-progress-filelist");
            });

            $root.on("afu-delete-file", (event, id) => {
                if (!id || $root.data("api_delete") === "") {
                    return false;
                }
                $root.addClass("afu-loading");
                $uploadedfilescounter.html("...");
                postRequest(
                    $root.data("api_delete"),
                    {
                        parent: $root.data("settings_parent"),
                        meta: JSON.stringify($root.data("settings_meta")),
                        id: id
                    }
                ).done(function () {
                    $root.trigger("afu-get-uploaded-files");
                });
            });

            $root.on("afu-delete-all", () => {
                if (uploadedfilelist.length <= 0 ||
                    $root.data("api_delete_selection") === "" ||
                    !confirm(lang.delete_all_confirm)) {
                    return false;
                }
                $root.addClass("afu-loading");
                $uploadedfilescounter.html("...");
                var selection = [];
                if (uploadedfilelist.length >= maxDelete) {
                    addflash('info', 'Es werden maximal ' + maxDelete + ' Dateien pro Aktion gelöscht.');
                }
                for (var i in uploadedfilelist) {
                    if (uploadedfilelist.hasOwnProperty(i)) {
                        selection.push(uploadedfilelist[i].id);
                    }
                }
                postRequest(
                    $root.data("api_delete_selection"),
                    {
                        parent: $root.data("settings_parent"),
                        meta: JSON.stringify($root.data("settings_meta")),
                        selection: selection
                    }
                ).done(function () {
                    $root.trigger("afu-get-uploaded-files");
                });
            });

            $root.on("afu-upload", () => {
                while (progressfilelist.length > 0 && parallel > 0) {
                    parallel--;
                    var file = progressfilelist.shift();
                    $root.trigger("afu-update-progress-filelist");
                    var skip = "";

                    var fileextension = getFileExtension(file);
                    if (fileextension === undefined || fileextension === '') {
                        continue;
                    }
                    if (fileextension === 'zip') {
                        uploadFile(file);
                        continue;
                    }
                    if (settings.extwhitelist.indexOf(fileextension) === -1) {
                        skip = file.name + "\n\n" + lang.fileextdenied + "\n\n" + settings.extwhitelist.join(", ");
                    } else if (settings.checkgeodatagermany) {
                        (function (aFile, geodataNotInGermany, showWarning, freeUploadSlot, uploadFile, window) { // creating scope for file
                            // @ts-ignore node_modules/exifr/dist/lite.umd.js
                            window.exifr.parse(aFile, {
                                pick: ['GPSLatitudeRef', 'GPSLongitudeRef', 'GPSMapDatum', 'GPSLatitude', 'GPSLongitude'],
                                gps: true,
                                silentErrors: true
                            }).then(function (geodata) {
                                var failureMessage = geodataNotInGermany(geodata); // error message OR false
                                if (failureMessage !== false) {
                                    showWarning(aFile.name + "\n\n" + failureMessage);
                                    freeUploadSlot();
                                } else {
                                    uploadFile(aFile);
                                }
                            }).catch(function (reason) {
                                showWarning(aFile.name + "\n\n" + reason);
                                freeUploadSlot();
                            });
                        })(file, geodataNotInGermany, showWarning, freeUploadSlot, uploadFile, window);
                    }
                    if (skip !== "") {
                        if (skip === null) { // fallback Fehlermeldung
                            skip = file.name + "\n\n" + lang.geodatamissing;
                        }
                        showWarning(skip);
                        freeUploadSlot();
                        $root.trigger("afu-update-progress-filelist-counter");
                        continue;
                    }
                    if (!settings.checkgeodatagermany) { // Sorry - aber der Geocheck läuft per Promise und asyncron.
                        uploadFile(file);
                        $root.trigger("afu-update-progress-filelist-counter");
                    }
                }
            });
//</editor-fold>

            $root.trigger("start");
        });
    });

    $document.trigger("start");
});
